import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import Checkout from "./Components/Pages/Checkout";
import OAuthCallback from "./Components/Pages/OAuthCallback";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import CenterScreenMessage from "./Components/CenterScreenMessage";
import { WarningAmber } from "@mui/icons-material";
import Header from "./Components/Header";
import { oldTrackingEnabled } from "./Utilities/tracking";

const queryClient = new QueryClient();
if (process.env.REACT_APP_PRODUCTION === "true") {
	const sentryDSN = process.env.REACT_APP_SENTRY_DSN;
	if (sentryDSN !== undefined) {
		Sentry.init({
			dsn: sentryDSN,
			integrations: [Sentry.browserTracingIntegration() ,Sentry.replayIntegration()],
			// Set tracesSampleRate to 1.0 to capture 100%
			// of transactions for performance monitoring.
			// We recommend adjusting this value in production
			tracesSampleRate: 0.1,
			// This sets the sample rate to be 10%. You may want this to be 100% while
			// in development and sample at a lower rate in production
			replaysSessionSampleRate: 0.01,
			// If the entire session is not sampled, use the below sample rate to sample
			// sessions when an error occurs.
			replaysOnErrorSampleRate: 0.01,
		});
	}

	const gaID = process.env.REACT_APP_GA_ID;
	if (oldTrackingEnabled && gaID !== undefined) {
		const googleAnalyticsScript = document.createElement("script");
		googleAnalyticsScript.src = "https://www.googletagmanager.com/gtag/js?id=" + gaID;
		document.head.appendChild(googleAnalyticsScript);
		// @ts-ignore: TODO remove no check
		window.dataLayer = window.dataLayer || [];
		// @ts-ignore: TODO remove no check
		function gtag() {
			// @ts-ignore: TODO remove no check
			dataLayer.push(arguments);
		}
		// @ts-ignore: TODO remove no check
		gtag("js", new Date());
		// @ts-ignore: TODO remove no check
		gtag("config", gaID);
	}
}

if (process.env.REACT_APP_TAG_MANAGER_ID !== undefined && !oldTrackingEnabled) {
	const tagManagerScript = document.createElement("script");
	tagManagerScript.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
	new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
	j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
	'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
	})(window,document,'script','dataLayer','${process.env.REACT_APP_TAG_MANAGER_ID}');`;
	document.head.appendChild(tagManagerScript);

	const tagManagerNoscript = document.createElement("noscript");
	tagManagerNoscript.innerHTML = `<iframe src={https://www.googletagmanager.com/ns.html?id=${process.env.REACT_APP_TAG_MANAGER_ID}}
	height="0" width="0" style={{ display: "none", visibility: "hidden" }} />`
	document.body.appendChild(tagManagerNoscript);
}

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container!);

root.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<BrowserRouter>
				<Sentry.ErrorBoundary
					fallback={
						<>
							<Header />
							<CenterScreenMessage
								icon={<WarningAmber sx={{ color: "orange", fontSize: "3em" }} />}
								message="Sorry! Something has gone wrong."
								subMessage="Please contract customer service on 0345 222 3336 or service@eden.co.uk"
							/>
						</>
					}
				>
					<Routes>
						<Route path="/oauth/callback" element={<OAuthCallback />} />
						<Route path="/:token" element={<Checkout />} />
						<Route
							path="/"
							element={
								<>
									<Header />
									<CenterScreenMessage
										icon={<WarningAmber sx={{ color: "orange", fontSize: "3em" }} />}
										message="Page not found!"
										subMessage=" Please go back to your basket and try again."
									/>
								</>
							}
						/>
						<Route element={<App></App>} />
					</Routes>
				</Sentry.ErrorBoundary>
			</BrowserRouter>
		</QueryClientProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
